#nav {
	grid-area: nav;
	font-size: 1.2em;
	gap: 18px;
}

#nav > .nav-btn {
	background-color: transparent;
	color: inherit;
	border: none;
}

#install-btn > svg {
	margin-bottom: 10px;
}
