@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

body.grid {
	grid-template-areas: "nav nav nav" ". main sidebar" "footer footer footer";
	grid-template-columns: 1fr minmax(400px, 70vw) 350px;
	grid-template-rows: var(--nav-height, 4rem) auto calc(100vh - var(--nav-height, 4rem));
}

.grid.contact-entries {
	grid-template-columns: repeat(auto-fit,350px);
	gap: 15px;
	justify-content: space-around;
}

.grid-gallery {
	grid-template-columns: repeat(auto-fit, minmax(220px, 400px));
	grid-template-rows: masonry;
	gap: 15px;
	justify-content: space-evenly;
}

.site-links {
	grid-template-columns: repeat(auto-fit, minmax(180px, 280px));
	gap: 15px;
	justify-content: space-evenly;
}

@media (max-width: 800px) {
	body.grid {
		grid-template-areas: "nav" "main" "sidebar" "footer";
		grid-template-columns: 100%;
		grid-template-rows: var(--nav-height, 4rem) auto auto calc(100vh - var(--nav-height, 4rem));
	}

	.site-links, .grid-gallery {
		grid-template-columns: 90vw;
	}
}

.home-section:not(:last-of-type) {
	margin-bottom: 15px;
}

.content {
	margin: 1.2em;
	padding: 0.4em 1.4em;
}

article header {
	padding: 0.8rem;
}

fieldset {
	padding: 2.3em;
}

legend {
	font-size: 1.4em;
	font-weight: bold;
}

dialog {
	max-height: var(--dialog-max-height, 90vh);
	max-width: var(--dialog-max-width, 90vw);
	overflow: auto;
}

.no-margins {
	margin: 0;
}

.icon-3vw {
	height: 3vw;
	width: 3vw;
}

.icon-3vmax {
	height: 3vmax;
	width: 3vmax;
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}

@media (min-width: 800px) {
	body.grid {
		grid-column-gap: 1.5rem;
	}
}
